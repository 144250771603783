import * as React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ArticleContent from '../article/articleContent';
import { FLOWTYPE_CVP, FLOWTYPE_PSP, FLOWTYPE_RLP, FLOWTYPE_SOPP } from '../../constants/flow';
import { CONTENT_TYPE_ARTICLE } from '../../constants/article';

function getSampleDisplayByFlowType (sample) {
    switch (sample.flowType) {
        case FLOWTYPE_PSP:
        case FLOWTYPE_RLP:
        case FLOWTYPE_SOPP:
        case FLOWTYPE_CVP:
            return (
                <React.Fragment>
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>{sample.beforePolishedTitle}</Typography>
                    <Paper elevation={0} sx={{
                        bgcolor: 'bg_article.main',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignSelf: 'center',
                        padding: '15mm',
                        overflow: 'auto',
                        border: '4px solid var(--bg-dark, #F0F0EB)',
                        width: '794px',
                        minHeight: '1123px',
                    }}>
                        {sample && (
                            <ArticleContent content={sample.originalArticle} flowType={sample.flowType} cvTemplateType={sample.cvTemplateType} contentTypeIdentifier={CONTENT_TYPE_ARTICLE} />
                        )}
                    </Paper>
                    <Typography variant="h5" sx={{ color: 'text.primary', textAlign: 'center' }}>{sample.afterPolishedTitle}</Typography>
                    <Paper elevation={0} sx={{
                        bgcolor: 'bg_article.main',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignSelf: 'center',
                        padding: '15mm',
                        overflow: 'auto',
                        border: '4px solid var(--bg-dark, #F0F0EB)',
                        width: '794px',
                        minHeight: '1123px',
                    }}>
                        {sample && (
                            <ArticleContent content={sample.polishedArticle} flowType={sample.flowType} cvTemplateType={sample.cvTemplateType} contentTypeIdentifier={CONTENT_TYPE_ARTICLE} />
                        )}
                    </Paper>
                </React.Fragment>
            );
        default:
            return (
                <Paper elevation={0} sx={{
                    bgcolor: 'bg_article.main',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignSelf: 'center',
                    padding: '15mm',
                    overflow: 'auto',
                    border: '4px solid var(--bg-dark, #F0F0EB)',
                    width: '794px',
                    minHeight: '1123px',
                }}>
                    {sample && (
                        <ArticleContent content={sample.content} flowType={sample.flowType} cvTemplateType={sample.cvTemplateType} />
                    )}
                </Paper>
            );
    }

}

export default function SampleDisplay({ sample }) {
    return (
        <Box sx={{
            display: 'flex',
            padding: '40px',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            justifyContent: 'center',
        }}>
            {sample && getSampleDisplayByFlowType(sample)}
        </Box>
    )
}