import * as React from 'react';
import Box from '@mui/material/Box';

export default function BasicLayout({ mainContent, leftDrawer }) {
  return (
    <Box sx={{ display: 'flex', marginTop: '64px' }}>
      {leftDrawer && leftDrawer}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: leftDrawer ? '240px' : 0,
          width: `calc(100vw - 240px)`,
          minHeight: `calc(100vh - 64px)`,
        }}
      >
        {mainContent}
      </Box>
    </Box>
  );
}