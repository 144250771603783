import * as React from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getFlowTypeIcon } from '../../utils/assets';
const drawerWidth = '240px';

export default function SampleDrawer({ currSampleId, sampleList, setCurrSampleId }) {
    
    const DrawerList = (
        <Box sx={{ width: drawerWidth }} role="presentation">
            <List>
                {sampleList && sampleList.map((sample, index) => (
                    <ListItem key={index} >
                        <ListItemButton sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '0 20px',
                            alignItems: 'center',
                            borderRadius: '10px',
                            width: '100%',
                            '&.Mui-selected': {
                                bgcolor: 'bg_dark.main',
                            },
                            '&.Mui-selected:hover': {
                                bgcolor: 'bg_dark.main',
                            },
                            '&:hover': {
                                bgcolor: 'bg_dark.main',
                            },
                        }} disabled={sample.disabled} onClick={() => setCurrSampleId(sample.id)} selected={currSampleId === sample.id}>
                            {sample.id === currSampleId ? (
                                <img src={getFlowTypeIcon({flowType: sample.flowType, color: 'primary'})} alt={sample.typeText} style={{ height: '20px', width: '20px', marginRight: '10px' }} />
                            ) : (
                                <img src={getFlowTypeIcon({ flowType: sample.flowType })} alt={sample.typeText} style={{ height: '20px', width: '20px', marginRight: '10px' }} />
                            )}
                            <ListItemText primary={
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}>
                                    <Typography variant='h7'>{sample.typeText}</Typography>
                                    {currSampleId === sample.id && (
                                        <ChevronRightIcon sx={{ height: '20px', width: '20px' }} />
                                    )}
                                </Box>
                            } />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    return (
        <Drawer
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    marginTop: '64px',
                    bgcolor: 'bg1.main'
                },
            }}
            variant="persistent"
            anchor="left"
            open
        >
            {DrawerList}
        </Drawer>
    );
}